import React from "react";
import { Typography, Stack, Container } from "@mui/material";
import WifiOffIcon from "@mui/icons-material/WifiOff";
import CheckCircleOutlineRoundedIcon from "@mui/icons-material/CheckCircleOutlineRounded";
import IntlMessages from "../../util/IntlMessages";

const InternetError = () => {
  return (
    <Container maxWidth="sm">
      <Stack
        spacing={3}
        alignItems="center"
        sx={{
          py: 4,
          px: 2,
        }}
      >
        <WifiOffIcon sx={{ fontSize: 130 }} />

        <Typography variant="h4" fontWeight="bold" textAlign="center">
          <IntlMessages id="DIC_SURVEY_RESPONSE_NO_INTERNET" />
        </Typography>

        <Typography textAlign="center">
          <IntlMessages id="DIC_SURVEY_RESPONSE_ERROR_MSG" />

          <Stack className="mt-4" spacing={2} width="100%">
            {[
              "DIC_SURVEY_RESPONSE_ERROR_MSG1",
              "DIC_SURVEY_RESPONSE_ERROR_MSG2",
            ].map((msgId) => (
              <Stack
                key={msgId}
                direction="row"
                spacing={2}
                alignItems="center"
              >
                <CheckCircleOutlineRoundedIcon color="primary" />
                <Typography>
                  <IntlMessages id={msgId} />
                </Typography>
              </Stack>
            ))}
          </Stack>
        </Typography>
      </Stack>
    </Container>
  );
};

export default InternetError;
