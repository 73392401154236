import {all, call, fork, put, takeEvery} from "redux-saga/effects";
import axios from "axios";
import {
    URL_ADMIN_CONTROLS_WC_SYNC_WORKER_CONTACTS_FROM_MS_SQL,
    URL_WC_ACTIVATE_WORKER_CONTACT_GROUPS,
    URL_WC_DEACTIVATE_CONTACT,
    URL_WC_DEACTIVATE_WORKER_CONTACT_GROUPS,
    URL_WC_DEACTIVATE_WORKER_CONTACTS,
    URL_WC_REACTIVATE_WORKER_CONTACTS,
    URL_WC_DELETE_WORKER_CONTACTS,
    URL_WC_DOWNLOAD_WORKER_CONTACTS,
    URL_WC_DOWNLOAD_WORKER_CONTACTS_ATTACHMENT_DOWNLOAD,
    URL_WC_DOWNLOAD_WORKER_CONTACTS_IMPORT_LIST,
    URL_WC_DOWNLOAD_WORKER_CONTACTS_TEMPLATE,
    URL_WC_DOWNLOAD_WORKER_CONTACTS_UPLOAD_EXCEL,
    URL_WC_GET_CONTACT,
    URL_WC_GET_CUSTOM_FIELDS_WITH_VALUES,
    URL_WC_GET_PAGINATED_LIST,
    URL_WC_GROUPS_GET_ACTIVE_LIST,
    URL_WC_GROUPS_GET_LIST,
    URL_WC_GROUPS_SAVE_GROUP,
    URL_WC_SAVE_CONTACT
} from "../../constants/Urls";
import AppUtils from "../../util/util";
import {
    WC_DOWNLOAD_WORKER_CONTACT_ATTACHMENT_DOWNLOAD,
    WC_DOWNLOAD_WORKER_CONTACT_TEMPLATE,
    WC_DOWNLOAD_WORKER_CONTACTS_IMPORT_LIST,
    WC_DOWNLOAD_WORKER_CONTACTS_UPLOAD_EXCEL,
    WC_GET_PAGINATED_LIST,
    WC_SYNC_MS_SQL_WORKER_CONTACTS,
    WC_WORKER_CONTACT_DEACTIVATE_CONTACT,
    WC_WORKER_CONTACT_GET_CONTACT,
    WC_WORKER_CONTACT_GET_CUSTOM_FIELDS_WITH_VALUES,
    WC_WORKER_CONTACT_GROUPS_ACTIVATE,
    WC_WORKER_CONTACT_GROUPS_DEACTIVATE,
    WC_WORKER_CONTACT_SAVE_CONTACT,
    WC_WORKER_CONTACTS_DEACTIVATE,
    WC_WORKER_CONTACTS_REACTIVATE,
    WC_WORKER_CONTACTS_DELETE,
    WC_WORKER_CONTACTS_DOWNLOAD,
    WC_WORKER_CONTACTS_GROUPS_GET_ACTIVE_LIST,
    WC_WORKER_CONTACTS_GROUPS_GET_LIST,
    WC_WORKER_CONTACTS_GROUPS_SAVE_GROUP
} from "../../constants/actions/wc/actionTypesWorkerContacts";

import {
    deactivateWorkerContactError,
    deactivateWorkerContactSuccess,
    getWCWorkerContactGroupsListError,
    getWCWorkerContactGroupsListSuccess,
    getWorkerContactActiveGroupListError,
    getWorkerContactActiveGroupListSuccess,
    getWorkerContactCustomFieldsWithValuesError,
    getWorkerContactCustomFieldsWithValuesSuccess,
    getWorkerContactError,
    getWorkerContactSuccess,
    saveWCWorkerContactError,
    saveWCWorkerContactGroupError,
    saveWCWorkerContactGroupSuccess,
    saveWCWorkerContactSuccess,
    wcDownloadWorkerContactAttachmentError,
    wcDownloadWorkerContactAttachmentSuccess,
    wcDownloadWorkerContactTemplateError,
    wcDownloadWorkerContactTemplateSuccess,
    wcGetPaginatedListError,
    wcGetPaginatedListSuccess,
    wcGetWorkerContactsImportListError,
    wcGetWorkerContactsImportListSuccess,
    wcGetWorkerContactsUploadExcelError,
    wcGetWorkerContactsUploadExcelSuccess,
    wcSyncMsSqlWorkerContactsError,
    wcSyncMsSqlWorkerContactsSuccess,
    wcWorkerContactDeactivateError,
    wcWorkerContactDeactivateSuccess,
    wcWorkerContactReactivateError,
    wcWorkerContactReactivateSuccess,
    wcWorkerContactDeleteError,
    wcWorkerContactDeleteSuccess,
    wcWorkerContactDownloadError,
    wcWorkerContactDownloadSuccess,
    wcWorkerContactGroupsActivateError,
    wcWorkerContactGroupsActivateSuccess,
    wcWorkerContactGroupsDeactivateError,
    wcWorkerContactGroupsDeactivateSuccess
} from "../../actions";


/************ methods : http *******************/
/** http : worker contacts : download worker contacts template **/
const httpWCDownloadWorkerContactsTemplate = async (data) =>
    await axios.post(URL_WC_DOWNLOAD_WORKER_CONTACTS_TEMPLATE, data, AppUtils.getHttpHeaders())
        .then(authUser => authUser)
        .catch(error => {
            return error
        });

/** http : worker contacts : download worker contacts template **/
const httpWCGetWorkerContactsImportList = async (data) =>
    await axios.post(URL_WC_DOWNLOAD_WORKER_CONTACTS_IMPORT_LIST, data, AppUtils.getHttpHeaders())
        .then(authUser => authUser)
        .catch(error => {
            return error
        });

/** http : worker contacts : upload contacts excel **/
const httpUploadWCWorkerContactsExcel = async (data) =>
    await axios.post(URL_WC_DOWNLOAD_WORKER_CONTACTS_UPLOAD_EXCEL, data, AppUtils.getHttpHeaders())
        .then(authUser => authUser)
        .catch(error => {
            return error
        });

/** http : worker contacts : to sync ms sql contacts to postgres **/
const httpWCSyncMsSqlWorkerContacts = async (data) =>
    await axios.post(URL_ADMIN_CONTROLS_WC_SYNC_WORKER_CONTACTS_FROM_MS_SQL, data, AppUtils.getHttpHeaders())
        .then(authUser => authUser)
        .catch(error => {
            return error
        });


/** http : worker contacts : to get paginated list **/
const httpWCGetPaginatedList = async (data) =>
    await axios.post(URL_WC_GET_PAGINATED_LIST, data, AppUtils.getHttpHeaders())
        .then(authUser => authUser)
        .catch(error => {
            return error
        });

/** http : worker contacts : download contacts attachment **/
const httpWCDownloadContactsAttachment = async (data) =>
    await axios.post(URL_WC_DOWNLOAD_WORKER_CONTACTS_ATTACHMENT_DOWNLOAD, data, AppUtils.getHttpHeaders())
        .then(authUser => authUser)
        .catch(error => {
            return error
        });

/** http : worker contacts : download **/
const httpWCContactsDownload = async (data) =>
    await axios.post(URL_WC_DOWNLOAD_WORKER_CONTACTS, data, AppUtils.getHttpHeaders())
        .then(authUser => authUser)
        .catch(error => {
            return error
        });

/** http : worker contacts : deactivate **/
const httpWCContactsDeactivate = async (data) =>
    await axios.post(URL_WC_DEACTIVATE_WORKER_CONTACTS, data, AppUtils.getHttpHeaders())
        .then(authUser => authUser)
        .catch(error => {
            return error
        });

/** http : worker contacts : reactivate **/
const httpWCContactsReactivate = async (data) =>
  await axios.post(URL_WC_REACTIVATE_WORKER_CONTACTS, data, AppUtils.getHttpHeaders())
    .then(authUser => authUser)
    .catch(error => {
        return error
    });

/** http : worker contacts : delete **/
const httpWCContactsDelete = async (data) =>
    await axios.post(URL_WC_DELETE_WORKER_CONTACTS, data, AppUtils.getHttpHeaders())
        .then(authUser => authUser)
        .catch(error => {
            return error
        });

/** http : worker contact groups : list **/
const httpWCGetContactsGroupsList = async (data) =>
    await axios.post(URL_WC_GROUPS_GET_LIST, data, AppUtils.getHttpHeaders())
        .then(authUser => authUser)
        .catch(error => {
            return error
        });

/** http : worker contact groups : save contact group **/
const httpWCSaveContactsGroup = async (data) =>
    await axios.post(URL_WC_GROUPS_SAVE_GROUP, data, AppUtils.getHttpHeaders())
        .then(authUser => authUser)
        .catch(error => {
            return error
        });


/** http : worker contact : save contact **/
const httpWCSaveContact = async (data) =>
    await axios.post(URL_WC_SAVE_CONTACT, data, AppUtils.getHttpHeaders())
        .then(authUser => authUser)
        .catch(error => {
            return error
        });

/** http : worker contact groups : active list **/
const httpWCGetContactsGroupsActiveList = async (data) =>
    await axios.post(URL_WC_GROUPS_GET_ACTIVE_LIST, data, AppUtils.getHttpHeaders())
        .then(authUser => authUser)
        .catch(error => {
            return error
        });

/** http : worker contact : to get contact **/
const httpWCGetContact = async (data) =>
    await axios.post(URL_WC_GET_CONTACT, data, AppUtils.getHttpHeaders())
        .then(authUser => authUser)
        .catch(error => {
            return error
        });

/** http : worker contact : to get custom fields with values **/
const httpWCGetCustomFieldsWithValues = async (data) =>
  await axios
    .get(
      `${URL_WC_GET_CUSTOM_FIELDS_WITH_VALUES}?client_id=${data.client_id}`,
      AppUtils.getHttpHeaders()
    )
    .then((data) => data)
    .catch((error) => {
      return error;
    });

/** http : worker contact : to deactivate contact **/
const httpWCDeactivateContact = async (data) =>
    await axios.post(URL_WC_DEACTIVATE_CONTACT, data, AppUtils.getHttpHeaders())
        .then(authUser => authUser)
        .catch(error => {
            return error
        });

/** http : worker contact groups : to deactivate contact groups **/
const httpWCDeactivateContactGroups = async (data) =>
    await axios.post(URL_WC_DEACTIVATE_WORKER_CONTACT_GROUPS, data, AppUtils.getHttpHeaders())
        .then(authUser => authUser)
        .catch(error => {
            return error
        });

/** http : worker contact groups : to activate contact groups **/
const httpWCActivateContactGroups = async (data) =>
    await axios.post(URL_WC_ACTIVATE_WORKER_CONTACT_GROUPS, data, AppUtils.getHttpHeaders())
        .then(authUser => authUser)
        .catch(error => {
            return error
        });

/************ methods : http response handler *******************/

/** worker contact groups : to activate contact groups **/
function* handleHttpWCActivateContactGroups({payload}) {
    try {
        let result = yield call(httpWCActivateContactGroups, payload);
        
        if (result.message) {
            if (result.response.data) {
                yield put(wcWorkerContactGroupsActivateError(result.response.data));
            } else {
                yield put(wcWorkerContactGroupsActivateError(result.response));
            }
            
        } else {
            if (result.data) {
                yield put(wcWorkerContactGroupsActivateSuccess(result.data));
            } else {
                yield put(wcWorkerContactGroupsActivateSuccess(result));
            }
        }
    } catch (error) {
        yield put(wcWorkerContactGroupsActivateError(error));
    }
}

/** worker contact groups : to deactivate contact groups **/
function* handleHttpWCDeactivateContactGroups({payload}) {
    try {
        let result = yield call(httpWCDeactivateContactGroups, payload);
        
        if (result.message) {
            if (result.response.data) {
                yield put(wcWorkerContactGroupsDeactivateError(result.response.data));
            } else {
                yield put(wcWorkerContactGroupsDeactivateError(result.response));
            }
            
        } else {
            if (result.data) {
                yield put(wcWorkerContactGroupsDeactivateSuccess(result.data));
            } else {
                yield put(wcWorkerContactGroupsDeactivateSuccess(result));
            }
        }
    } catch (error) {
        yield put(wcWorkerContactGroupsDeactivateError(error));
    }
}

/** worker contact : : to deactivate contact **/
function* handleHttpWCDeactivateContact({payload}) {
    try {
        let result = yield call(httpWCDeactivateContact, payload);
        
        if (result.message) {
            if (result.response.data) {
                yield put(deactivateWorkerContactError(result.response.data));
            } else {
                yield put(deactivateWorkerContactError(result.response));
            }
            
        } else {
            if (result.data) {
                yield put(deactivateWorkerContactSuccess(result.data));
            } else {
                yield put(deactivateWorkerContactSuccess(result));
            }
        }
    } catch (error) {
        yield put(deactivateWorkerContactError(error));
    }
}

/** worker contact : to get custom fields with values **/
function* handleHttpWCGetCustomFieldsWithValues({payload}) {
    try {
        let result = yield call(httpWCGetCustomFieldsWithValues, payload);
        
        if (result.message) {
            if (result.response.data) {
                yield put(getWorkerContactCustomFieldsWithValuesError(result.response.data));
            } else {
                yield put(getWorkerContactCustomFieldsWithValuesError(result.response));
            }
            
        } else {
            if (result.data) {
                yield put(getWorkerContactCustomFieldsWithValuesSuccess(result.data));
            } else {
                yield put(getWorkerContactCustomFieldsWithValuesSuccess(result));
            }
        }
    } catch (error) {
        yield put(getWorkerContactCustomFieldsWithValuesError(error));
    }
}

/** worker contact : to get contact by id **/
function* handleHttpWCGetContact({payload}) {
    try {
        let result = yield call(httpWCGetContact, payload);
        
        if (result.message) {
            if (result.response.data) {
                yield put(getWorkerContactError(result.response.data));
            } else {
                yield put(getWorkerContactError(result.response));
            }
            
        } else {
            if (result.data) {
                yield put(getWorkerContactSuccess(result.data));
            } else {
                yield put(getWorkerContactSuccess(result));
            }
        }
    } catch (error) {
        yield put(getWorkerContactError(error));
    }
}

/** worker contact groups : active list **/
function* handleHttpWCGetContactsGroupsActiveList({payload}) {
    try {
        let result = yield call(httpWCGetContactsGroupsActiveList, payload);
        
        if (result.message) {
            if (result.response.data) {
                yield put(getWorkerContactActiveGroupListError(result.response.data));
            } else {
                yield put(getWorkerContactActiveGroupListError(result.response));
            }
            
        } else {
            if (result.data) {
                yield put(getWorkerContactActiveGroupListSuccess(result.data));
            } else {
                yield put(getWorkerContactActiveGroupListSuccess(result));
            }
        }
    } catch (error) {
        yield put(getWorkerContactActiveGroupListError(error));
    }
}

/** worker contacts : to save contact **/
function* handleHttpWCSaveContact({payload}) {
    try {
        let result = yield call(httpWCSaveContact, payload);
        
        if (result.message) {
            if (result.response.data) {
                yield put(saveWCWorkerContactError(result.response.data));
            } else {
                yield put(saveWCWorkerContactError(result.response));
            }
            
        } else {
            if (result.data) {
                yield put(saveWCWorkerContactSuccess(result.data));
            } else {
                yield put(saveWCWorkerContactSuccess(result));
            }
        }
    } catch (error) {
        yield put(saveWCWorkerContactError(error));
    }
}

/** worker contacts groups : save **/
function* handleHttpWCSaveContactsGroup({payload}) {
    try {
        let result = yield call(httpWCSaveContactsGroup, payload);
        
        if (result.message) {
            if (result.response.data) {
                yield put(saveWCWorkerContactGroupError(result.response.data));
            } else {
                yield put(saveWCWorkerContactGroupError(result.response));
            }
            
        } else {
            if (result.data) {
                yield put(saveWCWorkerContactGroupSuccess(result.data));
            } else {
                yield put(saveWCWorkerContactGroupSuccess(result));
            }
        }
    } catch (error) {
        yield put(saveWCWorkerContactGroupError(error));
    }
}

/** worker contacts groups : get list **/
function* handleHttpWCGetContactsGroupsList({payload}) {
    try {
        
        console.log("handleHttpWCGetContactsGroupsList : called ")
        let result = yield call(httpWCGetContactsGroupsList, payload);
        
        if (result.message) {
            if (result.response.data) {
                yield put(getWCWorkerContactGroupsListError(result.response.data));
            } else {
                yield put(getWCWorkerContactGroupsListError(result.response));
            }
            
        } else {
            if (result.data) {
                yield put(getWCWorkerContactGroupsListSuccess(result.data));
            } else {
                yield put(getWCWorkerContactGroupsListSuccess(result));
            }
        }
    } catch (error) {
        yield put(getWCWorkerContactGroupsListError(error));
    }
}

/** worker contacts : delete contacts **/
function* handleHttpWCContactsDelete({payload}) {
    try {
        let result = yield call(httpWCContactsDelete, payload);
        
        if (result.message) {
            if (result.response.data) {
                yield put(wcWorkerContactDeleteError(result.response.data));
            } else {
                yield put(wcWorkerContactDeleteError(result.response));
            }
            
        } else {
            if (result.data) {
                yield put(wcWorkerContactDeleteSuccess(result.data));
            } else {
                yield put(wcWorkerContactDeleteSuccess(result));
            }
        }
    } catch (error) {
        yield put(wcWorkerContactDeleteError(error));
    }
}

/** worker contacts : deactivate contacts **/
function* handleHttpWCContactsDeactivate({payload}) {
    try {
        let result = yield call(httpWCContactsDeactivate, payload);
        
        if (result.message) {
            if (result.response.data) {
                yield put(wcWorkerContactDeactivateError(result.response.data));
            } else {
                yield put(wcWorkerContactDeactivateError(result.response));
            }
            
        } else {
            if (result.data) {
                yield put(wcWorkerContactDeactivateSuccess(result.data));
            } else {
                yield put(wcWorkerContactDeactivateSuccess(result));
            }
        }
    } catch (error) {
        yield put(wcWorkerContactDeactivateError(error));
    }
}

/** worker contacts : reactivate contacts **/
function* handleHttpWCContactsReactivate({payload}) {
    try {
        let result = yield call(httpWCContactsReactivate, payload);

        if (result.message) {
            if (result.response.data) {
                yield put(wcWorkerContactReactivateError(result.response.data));
            } else {
                yield put(wcWorkerContactReactivateError(result.response));
            }

        } else {
            if (result.data) {
                yield put(wcWorkerContactReactivateSuccess(result.data));
            } else {
                yield put(wcWorkerContactReactivateSuccess(result));
            }
        }
    } catch (error) {
        yield put(wcWorkerContactReactivateError(error));
    }
}

/** worker contacts : download contacts **/
function* handleHttpWCContactsDownload({payload}) {
    try {
        let result = yield call(httpWCContactsDownload, payload);
        
        if (result.message) {
            if (result.response.data) {
                yield put(wcWorkerContactDownloadError(result.response.data));
            } else {
                yield put(wcWorkerContactDownloadError(result.response));
            }
            
        } else {
            if (result.data) {
                yield put(wcWorkerContactDownloadSuccess(result.data));
            } else {
                yield put(wcWorkerContactDownloadSuccess(result));
            }
        }
    } catch (error) {
        yield put(wcWorkerContactDownloadError(error));
    }
}

/** worker contacts : download contacts attachment **/
function* handleHttpWCDownloadContactsAttachment({payload}) {
    try {
        let result = yield call(httpWCDownloadContactsAttachment, payload);
        
        if (result.message) {
            if (result.response.data) {
                yield put(wcDownloadWorkerContactAttachmentError(result.response.data));
            } else {
                yield put(wcDownloadWorkerContactAttachmentError(result.response));
            }
            
        } else {
            if (result.data) {
                yield put(wcDownloadWorkerContactAttachmentSuccess(result.data));
            } else {
                yield put(wcDownloadWorkerContactAttachmentSuccess(result));
            }
        }
    } catch (error) {
        yield put(wcDownloadWorkerContactAttachmentError(error));
    }
}

/** worker contacts : to get paginated list **/
function* handleHttpWCGetPaginatedList({payload}) {
    try {
        let result = yield call(httpWCGetPaginatedList, payload);
        
        if (result.message) {
            if (result.response.data) {
                yield put(wcGetPaginatedListError(result.response.data));
            } else {
                yield put(wcGetPaginatedListError(result.response));
            }
            
        } else {
            if (result.data) {
                yield put(wcGetPaginatedListSuccess(result.data));
            } else {
                yield put(wcGetPaginatedListSuccess(result));
            }
        }
    } catch (error) {
        yield put(wcGetPaginatedListError(error));
    }
}

/** worker contacts : to sync ms sql worker contacts to python **/
function* handleHttpWCSyncMsSqlWorkerContacts({payload}) {
    try {
        let result = yield call(httpWCSyncMsSqlWorkerContacts, payload);
        
        if (result.message) {
            if (result.response.data) {
                yield put(wcSyncMsSqlWorkerContactsError(result.response.data));
            } else {
                yield put(wcSyncMsSqlWorkerContactsError(result.response));
            }
            
        } else {
            if (result.data) {
                yield put(wcSyncMsSqlWorkerContactsSuccess(result.data));
            } else {
                yield put(wcSyncMsSqlWorkerContactsSuccess(result));
            }
        }
    } catch (error) {
        yield put(wcSyncMsSqlWorkerContactsError(error));
    }
}

/** worker contacts : to upload contacts excel **/
function* handleHttpUploadWCWorkerContactsExcel({payload}) {
    try {
        let result = yield call(httpUploadWCWorkerContactsExcel, payload);
        
        if (result.message) {
            if (result.response.data) {
                yield put(wcGetWorkerContactsUploadExcelError(result.response.data));
            } else {
                yield put(wcGetWorkerContactsUploadExcelError(result.response));
            }
            
        } else {
            if (result.data) {
                yield put(wcGetWorkerContactsUploadExcelSuccess(result.data));
            } else {
                yield put(wcGetWorkerContactsUploadExcelSuccess(result));
            }
        }
    } catch (error) {
        yield put(wcGetWorkerContactsUploadExcelError(error));
    }
}

/** worker contacts : to get import list **/
function* handleHttpWCGetWorkerContactsImportList({payload}) {
    try {
        let result = yield call(httpWCGetWorkerContactsImportList, payload);
        
        if (result.message) {
            if (result.response.data) {
                yield put(wcGetWorkerContactsImportListError(result.response.data));
            } else {
                yield put(wcGetWorkerContactsImportListError(result.response));
            }
            
        } else {
            if (result.data) {
                yield put(wcGetWorkerContactsImportListSuccess(result.data));
            } else {
                yield put(wcGetWorkerContactsImportListSuccess(result));
            }
        }
    } catch (error) {
        yield put(wcGetWorkerContactsImportListError(error));
    }
}

/** worker contacts : download worker contacts template : http response handler **/
function* handleHttpWCDownloadWorkerContactsTemplate({payload}) {
    try {
        let result = yield call(httpWCDownloadWorkerContactsTemplate, payload);
        
        if (result.message) {
            if (result.response.data) {
                yield put(wcDownloadWorkerContactTemplateError(result.response.data));
            } else {
                yield put(wcDownloadWorkerContactTemplateError(result.response));
            }
            
        } else {
            if (result.data) {
                yield put(wcDownloadWorkerContactTemplateSuccess(result.data));
            } else {
                yield put(wcDownloadWorkerContactTemplateSuccess(result));
            }
        }
        
    } catch (error) {
        yield put(wcDownloadWorkerContactTemplateError(error));
    }
}

/************ methods : export access *******************/

/** worker contacts : download worker contacts template **/
export function* wcDownloadWorkerContactsTemplate() {
    yield takeEvery(WC_DOWNLOAD_WORKER_CONTACT_TEMPLATE, handleHttpWCDownloadWorkerContactsTemplate);
}

/** worker contacts : to get import list **/
export function* getWCGetWorkerContactsImportList() {
    yield takeEvery(WC_DOWNLOAD_WORKER_CONTACTS_IMPORT_LIST, handleHttpWCGetWorkerContactsImportList);
}

/** worker contacts : to upload contacts excel **/
export function* uploadWCWorkerContactsExcel() {
    yield takeEvery(WC_DOWNLOAD_WORKER_CONTACTS_UPLOAD_EXCEL, handleHttpUploadWCWorkerContactsExcel);
}

/** worker contacts : to sync ms sql worker contacts to python **/
export function* wcSyncMsSqlWorkerContacts() {
    yield takeEvery(WC_SYNC_MS_SQL_WORKER_CONTACTS, handleHttpWCSyncMsSqlWorkerContacts);
}


/** worker contacts : to sync ms sql worker contacts to python **/
export function* wcGetPaginatedList() {
    yield takeEvery(WC_GET_PAGINATED_LIST, handleHttpWCGetPaginatedList);
}

/** worker contacts : download contact attachment **/
export function* wcDownloadContactsAttachment() {
    yield takeEvery(WC_DOWNLOAD_WORKER_CONTACT_ATTACHMENT_DOWNLOAD, handleHttpWCDownloadContactsAttachment);
}

/** worker contacts : download contacts **/
export function* wcContactsDownload() {
    yield takeEvery(WC_WORKER_CONTACTS_DOWNLOAD, handleHttpWCContactsDownload);
}

/** worker contacts : deactivate contacts **/
export function* wcContactsDeactivate() {
    yield takeEvery(WC_WORKER_CONTACTS_DEACTIVATE, handleHttpWCContactsDeactivate);
}

/** worker contacts : reactivate contacts **/
export function* wcContactsReactivate() {
    yield takeEvery(WC_WORKER_CONTACTS_REACTIVATE, handleHttpWCContactsReactivate);
}

/** worker contacts : delete contacts **/
export function* wcContactsDelete() {
    yield takeEvery(WC_WORKER_CONTACTS_DELETE, handleHttpWCContactsDelete);
}

/** worker contacts groups : get list **/
export function* getWCContactsGroupsList() {
    yield takeEvery(WC_WORKER_CONTACTS_GROUPS_GET_LIST, handleHttpWCGetContactsGroupsList);
}

/** worker contacts groups : save group **/
export function* wcSaveContactsGroup() {
    yield takeEvery(WC_WORKER_CONTACTS_GROUPS_SAVE_GROUP, handleHttpWCSaveContactsGroup);
}

/** worker contact : save contact **/
export function* wcSaveContact() {
    yield takeEvery(WC_WORKER_CONTACT_SAVE_CONTACT, handleHttpWCSaveContact);
}

/** worker contact : contact groups : get active list **/
export function* wcGetContactsGroupsActiveList() {
    yield takeEvery(WC_WORKER_CONTACTS_GROUPS_GET_ACTIVE_LIST, handleHttpWCGetContactsGroupsActiveList);
}

/** worker contact : contact groups : get active list **/
export function* wcGetContact() {
    yield takeEvery(WC_WORKER_CONTACT_GET_CONTACT, handleHttpWCGetContact);
}

/** worker contact : contact groups : get active list **/
export function* wcGetCustomFieldsWithValues() {
    yield takeEvery(WC_WORKER_CONTACT_GET_CUSTOM_FIELDS_WITH_VALUES, handleHttpWCGetCustomFieldsWithValues);
}

/** worker contact : to deactivate **/
export function* wcDeactivateContact() {
    yield takeEvery(WC_WORKER_CONTACT_DEACTIVATE_CONTACT, handleHttpWCDeactivateContact);
}

/** worker contact groups : to deactivate groups **/
export function* wcDeactivateContactGroups() {
    yield takeEvery(WC_WORKER_CONTACT_GROUPS_DEACTIVATE, handleHttpWCDeactivateContactGroups);
}

/** worker contact groups : to activate groups **/
export function* wcActivateContactGroups() {
    yield takeEvery(WC_WORKER_CONTACT_GROUPS_ACTIVATE, handleHttpWCActivateContactGroups);
}

export default function* rootSaga() {
    yield all([
        fork(wcDownloadWorkerContactsTemplate),
        fork(getWCGetWorkerContactsImportList),
        fork(uploadWCWorkerContactsExcel),
        fork(wcSyncMsSqlWorkerContacts),
        fork(wcGetPaginatedList),
        fork(wcDownloadContactsAttachment),
        fork(wcContactsDownload),
        fork(wcContactsDeactivate),
        fork(wcContactsReactivate),
        fork(wcContactsDelete),
        fork(getWCContactsGroupsList),
        fork(wcSaveContactsGroup),
        fork(wcSaveContact),
        fork(wcGetContactsGroupsActiveList),
        fork(wcGetContact),
        fork(wcGetCustomFieldsWithValues),
        fork(wcDeactivateContact),
        fork(wcDeactivateContactGroups),
        fork(wcActivateContactGroups)
    ]);
}


