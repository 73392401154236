import React from "react";
import { Box, Typography, Paper, useTheme, useMediaQuery } from "@mui/material";

/**
 * This component is called in signin component
 * If there are 502 errors from APIs, which means server is stopped,
 * we start showing this component instead of signin component
 */
function MaintenancePage() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      width="100%"
      height="100vh"
    >
      <Paper
        elevation={3}
        sx={{
          display: "inline-flex",
          padding: {
            xs: "40px 24px",
            sm: "80px 100px",
            md: "150px 200px",
          },
          flexDirection: "column",
          alignItems: "center",
          borderRadius: "4px",
          margin: {
            xs: "16px",
            sm: "0",
          },
          width: {
            xs: "100%",
            sm: "auto",
          },
          maxWidth: "90vw",
        }}
      >
        <Box
          component="img"
          src="/vendors/wovo/icons/common/wovo_maintenance_icon.svg"
          alt="Maintenance Icon"
          sx={{
            width: {
              xs: 48,
              sm: 64,
            },
            height: {
              xs: 48,
              sm: 64,
            },
            marginBottom: {
              xs: 2,
              sm: 4,
            },
          }}
        />
        <Typography
          variant="h4"
          sx={{
            fontWeight: 700,
            letterSpacing: "-0.9px",
            marginBottom: 1,
            fontFamily: "Inter, sans-serif",
            fontSize: {
              xs: "24px",
              sm: "30px",
              md: "34px",
            },
            textAlign: "center",
          }}
        >
          We'll be back.
        </Typography>
        <Typography
          variant="subtitle1"
          sx={{
            fontWeight: 600,
            letterSpacing: "-0.6px",
            fontFamily: "Inter, sans-serif",
            fontSize: {
              xs: "14px",
              sm: "16px",
            },
            textAlign: "center",
          }}
        >
          WOVO is under maintenance. Please check back soon.
        </Typography>
      </Paper>
    </Box>
  );
}

export default MaintenancePage;
