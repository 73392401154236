import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import IntlMessages from "util/IntlMessages";
import CircularProgress from "@material-ui/core/CircularProgress";
import {
  hideMessage,
  showAuthLoader,
  userFacebookSignIn,
  userGithubSignIn,
  userGoogleSignIn,
  userSignIn,
  userTwitterSignIn,
} from "actions/Auth";
import { getTranslationsList } from "../actions/adminSettings/translations/strings/actionsTranslationStrings";
import AppUtils from "../util/util";
import { CustomCookieBanner } from "../components/CookieBanner";
import { WOVO_INTERACT_COOKIE_KEY } from "../constants/config";
import MaintenancePage from "components/MaintenancePage";
import { MOBILE_VIEW } from "../constants/constants";

class SignIn extends React.Component {
  constructor() {
    super();
    this.state = {
      //username: 'kaushik_Admin',
      //username: 'Demo_User',
      //username: 'Krystal',
      //username: 'Aditya',
      //password: 'Wovo@123'
      /*username : 'Adelina.Simanjuntak',
            password : 'snoWdep32',*/
      username: "",
      password: "",
      isCookiePresent: false,
    };
  }

  componentDidMount() {
    this.props.getTranslationsList();
    AppUtils.redirectToValidDomain();

    /**check if cookie already present */
    this.setCookieState();
  }

  componentDidUpdate() {
    if (this.props.showMessage) {
      setTimeout(() => {
        this.props.hideMessage();
      }, 100);
    }

    /*if (this.props.authUser !== null) {
            this.props.history.push('/');
        }*/
  }

  handleChange = (name, value) => {
    switch (name) {
      case "username": {
        this.setState({
          username: value,
        });
        break;
      }

      case "password": {
        this.setState({
          password: value,
        });
        break;
      }

      case "accept_cookie": {
        localStorage.setItem(WOVO_INTERACT_COOKIE_KEY, value);
        this.setState({
          isCookiePresent: value,
        });
        break;
      }

      default: {
        break;
      }
    }
  };

  /**To set cookie state to true if cookie already present */
  setCookieState = () => {
    try {
      let cookieValue = localStorage.getItem(WOVO_INTERACT_COOKIE_KEY);
      if (cookieValue !== null && cookieValue) {
        this.setState({
          isCookiePresent: true,
        });
      }
    } catch (ex) {
      console.error("setCookieState : error : ", ex);
    }
  };

  render() {
    const { username, password, isCookiePresent } = this.state;
    const {
      showMessage,
      loader,
      alertMessage,
      authUser,
      enableMaintenancePage,
    } = this.props;

    if (
      authUser != null &&
      typeof authUser.user != "undefined" &&
      enableMaintenancePage === false
    ) {
      this.props.history.push("/");
    }

    return (
      <>
        {/**Show Maintenance page if flag is enabled else show the signin page */
        enableMaintenancePage === true ? (
          <MaintenancePage />
        ) : (
          <div className="app-login-container d-flex justify-content-center align-items-center animated slideInUpTiny animation-duration-3">
            <div className="login-content">
              <div className="login-header">
                <Link className="app-logo" to="/" title="Wovo">
                  <img
                    src={require("assets/images/wovo.svg")}
                    alt="Wovo"
                    title="Wovo"
                  />
                </Link>
              </div>

              <div className="login-form">
                <form>
                  <fieldset>
                    <TextField
                      label={<IntlMessages id="DIC_LOGIN_USER_NAME" />}
                      fullWidth
                      onChange={(event) =>
                        this.handleChange("username", event.target.value)
                      }
                      value={username}
                      margin="normal"
                      className="mt-1 my-sm-3"
                    />
                    <TextField
                      type="password"
                      label={<IntlMessages id="DIC_LOGIN_PASSWORD" />}
                      fullWidth
                      onChange={(event) =>
                        this.handleChange("password", event.target.value)
                      }
                      margin="normal"
                      className="mt-1 my-sm-3"
                      value={password}
                    />

                    <div className="mb-3 d-flex align-items-center justify-content-between">
                      <Button
                        onClick={() => {
                          this.props.showAuthLoader();
                          this.props.userSignIn({ username, password });
                        }}
                        variant="contained"
                        color="primary"
                        disabled={!isCookiePresent}
                      >
                        <IntlMessages id="DIC_LOGIN_SIGN_IN" />
                      </Button>
                    </div>
                  </fieldset>
                </form>
              </div>
            </div>
            {loader && (
              <div className="loader-view">
                <CircularProgress />
              </div>
            )}
            {showMessage && NotificationManager.error(alertMessage)}
            <NotificationContainer />
          </div>
        )}
        {/**Show the cookie banner only in case of signin page, not for the maintenance page */
        !isCookiePresent && enableMaintenancePage === false && (
          <CustomCookieBanner handleButtonClick={this.handleChange} />
        )}
      </>
    );
  }
}

const mapStateToProps = ({ auth, ReducerMaintenanceBanner }) => {
  const { loader, alertMessage, showMessage, authUser } = auth;
  const { enableMaintenancePage } = ReducerMaintenanceBanner;
  return { loader, alertMessage, showMessage, authUser, enableMaintenancePage };
};

export default connect(mapStateToProps, {
  userSignIn,
  hideMessage,
  showAuthLoader,
  userFacebookSignIn,
  userGoogleSignIn,
  userGithubSignIn,
  userTwitterSignIn,
  getTranslationsList,
})(SignIn);
